'use client';

import React, { useState } from 'react';

import Header from '../organisms/header';
import Sidebar from '../organisms/sidebar';
import { UserContextProvider } from '@/app/contexts/userContext';
import { Client, ClientPlan, User } from '@prisma/client';
import { MenuManagerProvider } from '@/app/contexts/menuManagerContext';

interface LayoutProps {
    children: React.ReactNode;
    user: User;
    client?: Client;
    clientPlan?: ClientPlan;
}

const Layout = ({ children, ...userInfo }: LayoutProps) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div>
            <UserContextProvider {...userInfo}>
                <MenuManagerProvider>
                    <Header onSidebarToggle={handleSidebarToggle} />
                    <Sidebar isOpen={isSidebarOpen} />
                    <section
                        className={`transition-all ${isSidebarOpen ? 'md:ml-80' : ''} ${
                            userInfo?.clientPlan?.name === 'FREE' ? 'pt-40' : 'pt-[104px]'
                        } pl-2 md:pl-4 2xl:pl-12 pr-2 md:pr-10 2xl:pr-60  2xl:pb-6 md:pb-[100px] pb-[80px]`}
                    >
                        {children}
                    </section>
                </MenuManagerProvider>
            </UserContextProvider>
        </div>
    );
};

export default Layout;
